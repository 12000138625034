import React, { useState } from 'react';

import './App.css';
import Login from './components/Login';
import AuthContent from './components/AuthContent';
import NonAuthContent from './components/NonAuthContent';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("ServiceWorker.js").then(registration => {
          console.log("SW Registered!");
      }).catch(error => {
          console.log("SW Registration Failed");
      });
  } else {
      console.log("Not supported");
  }

  return (
    <div>
      <Login
        setIsAuthenticated={setIsAuthenticated}
      />
      {isAuthenticated ? (
        <AuthContent/>
      ) : (
        <NonAuthContent/>
      )}
    </div>
  );
}

export default App;
